import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import {handleGetError, handlePostPatchError} from '../helpers/errors';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate} from '../helpers/utils';
import EvidenceTable from '../evidence/evidenceTable'
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


class Activity extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            showLoader: true,
            isSaved: true,
            users: [],
            name: '',
            description: '',
            notes: '',
            assignedUserId: '',
            assignedUser: '',
            enteredDate: '',
            dueDate: '',
            completedDate: ''
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;

        try {
            let activity = {};
            let users = [];

            if (!_new) {
                activity = await fpRest.get(`activities/${id}`);
                activity = activity.data;
            }

            if (editing) {
                users = await fpRest.get(`users`);
                users = users.data.map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
            }

            console.log(users);
            this.setState({...activity, users, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };  

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    edit = () => {
        this.props.history.push(`/activities/${this.props.id}/edit`)
    };    
    
    validate = ({
        name,
        assignedUserId
    }) => {
        let invalidFields = [];
    
        if (!name) invalidFields.push('"Name" is required');
        if (!assignedUserId) invalidFields.push('"Assigned To" is required');
    
        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            name,
            description,
            notes,
            assignedUserId,
            dueDate,
            completedDate
        } = this.state;
        const persistedState = {
            name,
            description,
            notes,
            assignedUserId,
            dueDate,
            completedDate
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`activities/${id}`, persistedState);
                    if (secondAction === 'close') {
                         history.push(`/activities/${id}`);
                    }
                    
                    toast.success('To Do Saved');
                }
                else {
                    const {data} = await fpRest.post('activities', persistedState);
                    if (secondAction === 'close') {
                        history.push(`/activities/`);
                    }
                    else {
                        history.push(`/activities/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/activities/${this.props.id}`);
        }
        else {
            this.props.history.push(`/activities/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            users,
            name,
            description,
            notes,
            assignedUserId,
            assignedUser,
            enteredDate,
            dueDate,
            completedDate
        } = this.state;
        const {onFieldChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}To Do</span>
                                {name && <span>: {truncate(name, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All Activitys', link: '/activities/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">To Do:</span> {truncate(name, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: `All To Do's`, link: '/activities/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit To Do', action: edit},
                            {label: <Svg use="add" />, tip: 'New To Do', link: '/activities/new'}
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: enteredDate, inputType: 'date'}
                            ]}
                        />
                    </section>
                }           
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Name', value: name, accessor:'name', required: true},                             
                            {label: 'Due Date', value: dueDate, accessor:'dueDate', inputType: 'date'}, 
                            {label: 'Assigned To', value: editing ? assignedUserId : assignedUser, accessor: 'assignedUserId', inputType: 'select', options: users, required: true},
                            {label: 'Completed Date', value: completedDate, accessor:'completedDate', inputType: 'date'},
                            {label: 'Description', value: description, accessor: 'description', inputType: 'textarea'},   
                            {label: 'Notes', value: notes, accessor: 'notes', inputType: 'textarea'},                         
                        ]}
                    />                                              
                </section>               
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(Activity));