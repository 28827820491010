import React, {Component} from 'react';
import {withRouter, Prompt} from 'react-router-dom';
import Svg from '../helpers/svg';
import List from '../helpers/list';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import withCurrentUser from '../helpers/withCurrentUser';
import {truncate} from '../helpers/utils';
import EvidenceTable from '../evidence/evidenceTable'
import 'react-table/react-table.css';
import Loader from 'react-loader-advanced';
import {toast} from 'react-toastify';
import {handleGetError, handlePostPatchError} from '../helpers/errors';


class InformationSystemActivityReview extends Component {
    constructor(props) {
        super(props) 

        this.state = {
            showLoader: true,
            isSaved: true,
            users: [],
            linkedEvidence: [],
            dateEntered: '',
            datePerformed: '', 
            requestedById: '',
            requestedBy: '',
            reasonFor: '',
            performedById: '',
            performedBy: '',
            rptAnalysis: '',
            rptConclusions: '',
            rptRecommendations: '',
            reportedToId: '',
            reportedTo: '',
            rptReviewed: '',
            rptActions: '',
            name: ''
        };            
    };

    getData = async () => {
        const {id, editing, _new} = this.props;

        try {
            let informationSystemActivityReview = {};
            let users = [];

            if (!_new) {
                informationSystemActivityReview = await fpRest.get(`information-system-activity-reviews/${id}`);
                informationSystemActivityReview = informationSystemActivityReview.data;
            }

            if (editing) {
                users = await fpRest.get(`users`);
                users = users.data.filter(({active}) => active).map(({id, firstName, lastName}) => ({value: id, label: `${firstName} ${lastName}`}));
            }

            console.log(users);
            this.setState({...informationSystemActivityReview, users, showLoader: false});
        }
        catch (err) {
            handleGetError(err, this.props);
        }
    };

    downloadEvidence = async id => {
        this.setState({showLoader: true});

        try {
            const {data} = await fpRest.get(`evidence/${id}`);

            let element = document.createElement('a');

            element.setAttribute('href', data.tempDownloadUrl);
            element.style.display = 'none';
            document.body.appendChild(element);
            element.click();
            document.body.removeChild(element);
            this.setState({showLoader: false});          
        }
        catch (err) {
            console.log(err);
        } 
    };    

    onEvidenceUploadComplete = async (newEvidence) => {
        this.setState({linkedEvidence: [...this.state.linkedEvidence, newEvidence]});
    }; 
    
    deleteEvidence = async id => {
        try {
            await fpRest.delete(`evidence/${id}`);

            const linkedEvidence = this.state.linkedEvidence.filter(evidence => evidence.id === id ? false : true);

            this.setState({linkedEvidence});
            toast.success('Evidence Deleted');
        }
        catch(err) {
            console.log(err);
        }
    };      

    onFieldChange = ({accessor, value}) => {
        this.setState({
            [accessor]: value,
            isSaved: false
        });
    };

    edit = () => {
        this.props.history.push(`/information-system-activity-reviews/${this.props.id}/edit`)
    };    
    
    validate = ({
        name
    }) => {
        let invalidFields = [];

        if (!name) invalidFields.push('"Name" is required');

        return invalidFields;
    };

    save = async secondAction => {
        const {id, history} = this.props;
        const {
            datePerformed,
            requestedById,
            reasonFor,
            performedById,
            rptAnalysis,
            rptConclusions,
            rptRecommendations,
            reportedToId,
            rptReviewed,
            rptActions,
            name
        } = this.state;
        const persistedState = {
            datePerformed,
            requestedById,
            reasonFor,
            performedById,
            rptAnalysis,
            rptConclusions,
            rptRecommendations,
            reportedToId,
            rptReviewed,
            rptActions,
            name
        };
        const invalidFields = this.validate(persistedState);

        this.setState({isSaved: true});

        if (!invalidFields.length) {
            try {
                if (id) {
                    await fpRest.patch(`information-system-activity-reviews/${id}`, persistedState);
                    toast.success('Activity Review Saved');

                    if (secondAction === 'close') {
                         history.push(`/information-system-activity-reviews/${id}`);
                    }
                }
                else {
                    const {data} = await fpRest.post('information-system-activity-reviews', persistedState);
                    toast.success('Activity Review Saved');

                    if (secondAction === 'close') {
                        history.push(`/information-system-activity-reviews/`);
                    }
                    else {
                        history.push(`/information-system-activity-reviews/${data.id}`);
                    }
                }
            }
            catch (err) {
                this.setState({isSaved: false});
                handlePostPatchError(err);
            }
        }
        else {
            this.setState({isSaved: false});

            invalidFields.forEach(message => {
                toast.error(message);
            });
        } 
    };

    close = () => {
        if (this.props.id) {
            this.props.history.push(`/information-system-activity-reviews/${this.props.id}`);
        }
        else {
            this.props.history.push(`/information-system-activity-reviews/`);
        }
    };

    componentDidMount() {
        this.getData();
    };

    render() {
        const {id, editing, _new, history} = this.props;
        const {
            showLoader,
            isSaved,
            users,
            linkedEvidence,
            dateEntered,
            datePerformed,
            requestedById,
            requestedBy,
            reasonFor,
            performedById,
            performedBy,
            rptAnalysis,
            rptConclusions,
            rptRecommendations,
            reportedToId,
            reportedTo,
            rptReviewed,
            rptActions,
            name,
        } = this.state;
        const {downloadEvidence, onEvidenceUploadComplete, deleteEvidence,onFieldChange, edit, save, close} = this;

        let saveButtonClass = '';

        if (!isSaved) saveButtonClass = 'warning';

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                {editing ?
                    <PageHeader title={
                            <span>
                                <span className="brand-red">{_new && <span>New </span>}Activity Review</span>
                                {name && <span>: {truncate(name, 15)}</span>}
                            </span>
                        }
                        actionItems={[
                            //{label: <Svg use='briefcase'/>, tip: 'All Activity Reviews', link: '/information-system-activity-reviews/'},
                            {label: <Svg use='close'/>, tip: 'Close', action: close},
                            {label: <Svg use='save'/>, tip:'Save', action: save, className: saveButtonClass},
                            {label: <Svg use='save-close'/>, tip: 'Save & Close', action: () => save('close')}
                        ]}
                    />
                    :
                    <PageHeader title={<span><span className="brand-red">Activity Review:</span> {truncate(name, 15)}</span>}
                        actionItems={[
                            {label: <Svg use='arrow-left'/>, tip: 'All Activity Reviews', link: '/information-system-activity-reviews/'},
                            {label: <Svg use='edit'/>,  tip: 'Edit Activity Review', action: edit},
                            {label: <Svg use="add" />, tip: 'New Activity Review', link: '/information-system-activity-reviews/new'}
                        ]}
                    />
                }
                {!_new &&
                    <section>
                        <List
                            className="meta"
                            inlineLabels={true}
                            items ={[
                                {label: 'Entered Date', value: dateEntered, inputType: 'date'}
                            ]}
                        />
                    </section>
                }           
                <section>
                    <List
                        className=" card grid-3"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Name', value: name, accessor:'name', required: true},
                            {label: 'Date Performed', value: datePerformed, accessor:'datePerformed', inputType: 'date'},                              
                            {label: 'Reason For Review', value: reasonFor, accessor: 'reasonFor'},
                            {label: 'Requested By', value: editing ? requestedById : requestedBy, accessor: 'requestedById', inputType: 'select', options: users},
                            {label: 'Performed By', value: editing ? performedById : performedBy, accessor: 'performedById', inputType: 'select', options: users},
                            {label: 'Reported To', value: editing ? reportedToId : reportedTo, accessor: 'reportedToId', inputType: 'select', options: users},
                            {label: 'Report Has Been Reviewed', value: editing ? rptReviewed : rptReviewed ? 'Yes' : 'No', accessor:'rptReviewed', inputType: 'toggle'},
                        ]}
                    />                                              
                </section>
                <section>
                    <h2>Review</h2>
                    <List
                        className=" cards grid-2 labels-alt-1"
                        editing={editing}
                        onChange={onFieldChange}
                        items={[
                            {label: 'Analysis', value: rptAnalysis, accessor:'rptAnalysis', inputType: 'textarea'},
                            {label: 'Conclusions', value: rptConclusions, accessor:'rptConclusions', inputType: 'textarea'},
                            {label: 'Recommendations', value: rptRecommendations, accessor:'rptRecommendations', inputType: 'textarea'},
                            {label: 'Actions', value: rptActions, accessor:'rptActions', inputType: 'textarea'},                            
                        ]}
                    />                                              
                </section>
                {!_new &&
                    <section>
                        <EvidenceTable
                            className="card"
                            data={linkedEvidence}
                            onUploadComplete={onEvidenceUploadComplete}
                            onRequestDownload={downloadEvidence}
                            onRequestDelete={deleteEvidence}
                            isEditing={true}
                            parentId={id}
                            typeId={7}
                        />                    
                    </section>
                }                
                <Prompt when={editing && !isSaved} message={location => `You have changes that arn't saved. Click "Okay" to discard these changes, or "Cancel" to stay on this page`}/>                               
                
            </Loader>
        );
    }
}

export default withRouter(withCurrentUser(InformationSystemActivityReview));