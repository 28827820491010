import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


import 'react-table/react-table.css';

class SecurityAwareness extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`securityAwareness:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'enteredDate', desc: true},
            users: [],
            securityAwareness: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            isDownloadDropdownOpen: false       
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        Promise.all([fpRest.get('security-awareness'), fpRest.get('users')])
        .then(res => {
            const securityAwareness = res[0].data.map(activity => ({...activity, completed: activity.completedDate ? true : false}));
            const users = res[1].data.map(({firstName, lastName}) => ({value: `${firstName} ${lastName}`, label: `${firstName} ${lastName}`}));
            console.log(securityAwareness);
            this.setState({users, securityAwareness, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };
    
    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };    

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadSecurityAwareness = securityAwareness => {
        const getData = this.getData.bind(this);
        const policyPromises = securityAwareness.map(log => fpRest.post('security-awareness', {...log, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            toast.success('Training Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    toast.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                toast.error(`Oops! Something didn't work right. Training wasn't imported`);
            }
        });
    };

    downloadAllSecurityAwarenessEntries = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=securityAwarenessEntriesAll&format=csv`, `Training Entries.csv`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no Training Entries');   
        }
        catch (err) {
            console.log(err);
        }
    };    

    printAll = () => {
        console.log('Print All');
    };

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadSecurityAwareness, toggleInactive, toggleDownloadDropdown, downloadAllSecurityAwarenessEntries} = this;
        const {showLoader, initSortOrder, users, securityAwareness, hideInactive, isImporterOpen, isImportDropdownOpen, isDownloadDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Training" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Training', link: '/security-awareness/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Training', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/securityAwareness-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown}
                    {label: <Svg use='download'/>, tip:'Download',
                    options: [
                        {label: 'Download All Training Entries (CSV)', action: downloadAllSecurityAwarenessEntries}
                    ],
                    isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown}
                
                ]} />             
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={securityAwareness}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Entered Date',
                                    accessor: 'enteredDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                },
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Topic',
                                    accessor: 'topic',
                                },                                 
                                {
                                    Header: 'Presented By',
                                    accessor: 'presentedBy',
                                },                                
                                {
                                    Header: 'Description',
                                    accessor: 'description',
                                },
                                {
                                    Header: 'Event Date',
                                    accessor: 'eventDate',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'

                                }                                                                                                                                                                    
                            ]}
                            noDataText="No Entries Found"
                            onSortedChange={sort => sessionStorage.setItem(`securityAwareness:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/security-awareness/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                
           </Loader>
        );
    };
}

export default withRouter(SecurityAwareness);