import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import settingsHeaderNav from '../helpers/settingsHeaderNav';
import Cards from '../helpers/cards';
import Loader from 'react-loader-advanced';
import {toast} from 'react-toastify';
import 'react-table/react-table.css';

class BusinessAssociatSettingsRoute extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showLoader: true,
            associateServices: [],
            isImporterOpen: false,
            hideInactive: true,
            isNewDropdownOpen: false,
            isImportDropdownOpen: false,
            shouldCreateAssociateService: false,
        };
    };

    getData = async () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});
        try {
            const res = await fpRest.get(`business-associates/services`);
            const associateServices = res.data;
            this.setState({associateServices, showLoader: false});
        }
        catch (err) {
            console.log(err);
        };          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };
    
    toggleNewDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isNewDropdownOpen});
    };

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    createAssociateServices = () => {
        this.setState({shouldCreateAssociateService: true}, () => {
            this.setState({shouldCreateAssociateService: false});
        });
    }   

    saveAssociatesService = (all, item, service, dest, action) => {
        this.setState({associateServices: all[0].associateServices, showLoader: true});
        if (action === 'create') {
            fpRest.post(`business-associates/services`, service)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
        else if (action === 'update') {
            fpRest.patch(`business-associates/services/${service.id}`, service)
            .then(res => {
                this.getData();
            })
            .catch(err => {
                console.log(err);
                this.getData();
            });  
        }
    };   

    componentDidMount() {
        this.getData();        
    }

    render() {
        const {toggleImportDropdown, toggleNewDropdown, startImport, closeImporter, toggleInactive, saveAssociatesService, createAssociateServices} = this;
        const {showLoader, associateServices, maintenanceItems, hideInactive, isImporterOpen, isImportDropdownOpen, isNewDropdownOpen, shouldCreateAssociateService} = this.state;

        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader
                    navItems={settingsHeaderNav('associateServices')}
                    actionItems={[]} 
                />
                <Cards
                    items={[{name: 'Contractor Services', associateServices}]}
                    mappings={{
                        header: {
                            accessor: 'name',
                            tag:'h2'
                        },
                        subItems: {
                            accessor: 'associateServices',
                            newItemText: 'Contractor Service',
                            header: {
                                accessor: 'name',
                                tag: 'h3'
                            }
                        }
                    }}
                    areItemsEditable={false}
                    onSave={saveAssociatesService}
                />           
                
            </Loader>
        );
    };
}

export default withRouter(BusinessAssociatSettingsRoute);