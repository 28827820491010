import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import moment from 'moment';
import Svg from '../helpers/svg';
import PageHeader from '../helpers/pageHeader';
import fpRest from '../helpers/fpRest';
import CsvImporter from '../helpers/csvImporter';
import List from '../helpers/list';
import {defaultTableFilter, DorpDownFilter} from '../helpers/tableHelpers';
import ReactTable from 'react-table';
import Loader from 'react-loader-advanced';
 import { toast } from 'react-toastify';


import 'react-table/react-table.css';

class InformationSystemActivityReviews extends Component {
    constructor(props) {
        super(props)
        const initSortOrder = sessionStorage.getItem(`informationSystemActivityReviews:sortOrder`);

        this.state = {
            showLoader: true,
            initSortOrder: initSortOrder ? JSON.parse(initSortOrder) : {id: 'dateEntered', desc: true},
            informationSystemActivityReviews: [],
            informationSystemActivityReviewsPending: [],
            isImporterOpen: false,
            hideInactive: true,
            isImportDropdownOpen: false,
            isDownloadDropdownOpen: false                 
        };
    };

    getData = () => {
        if (this.state.showLoader === false) this.setState({showLoader: true});

        fpRest.get('information-system-activity-reviews')
        .then(res => {
            const informationSystemActivityReviews = res.data;
            console.log(informationSystemActivityReviews);
            const informationSystemActivityReviewsPending = informationSystemActivityReviews.filter(({rptReviewed}) => rptReviewed === false);
            console.log(informationSystemActivityReviews);
            this.setState({informationSystemActivityReviews, informationSystemActivityReviewsPending, showLoader: false});
        })
        .catch(err => console.log(err));          
    };

    startImport = () => {
        this.setState({isImporterOpen: true});
    };

    closeImporter = () => {
        this.setState({isImporterOpen: false});
    };

    toggleImportDropdown = () => {
        this.setState({isImportDropdownOpen:!this.state.isImportDropdownOpen});
    };
    
    toggleDownloadDropdown = () => {
        this.setState({isDownloadDropdownOpen:!this.state.isDownloadDropdownOpen});
    };                

    toggleInactive = () => {
        this.setState({hideInactive: !this.state.hideInactive});
    };

    uploadInformationSystemActivityReviews = informationSystemActivityReviews => {
        const getData = this.getData.bind(this);
        const policyPromises = informationSystemActivityReviews.map(log => fpRest.post('information-system-activity-reviews', {...log, active: 1}));
        Promise.all(policyPromises)
        .then(res => {
            getData();
            toast.success('Information System Activity Review Uploaded');
        })
        .catch(err => { 
            if (err.response && err.response.data) {
                err.response.data.errors.forEach(err => {
                    toast.error(`import failed: ${err}`);
                });
                console.log(err.response.data);
            }
            else {
                console.log(err);
                toast.error(`Oops! Something didn't work right. Activity Reviews weren't imported`);
            }
        });
    };

    printAll = () => {
        console.log('Print All');
    };

    downloadAllInformationSystemActivityReviews = async () => {
        this.setState({showLoader: true, isLoadingReport: true});
        
        try {
            const res = await fpRest.download(`reportsTest?name=informationSystemActivityReviewsAll&format=csv`, `Activity Reviews.csv`);  
            this.setState({showLoader: false, isLoadingReport: false});    
            if (res.message == 'No data found') toast.success('There are no Activity Reviews');   
        }
        catch (err) {
            console.log(err);
        }
    };    

    componentDidMount() {
        this.getData();        
    };

    render() {
        const {toggleImportDropdown, startImport, closeImporter, uploadInformationSystemActivityReviews, toggleInactive, downloadAllInformationSystemActivityReviews, toggleDownloadDropdown} = this;
        const {showLoader, initSortOrder, informationSystemActivityReviews, informationSystemActivityReviewsPending, hideInactive, isImporterOpen, isImportDropdownOpen, isDownloadDropdownOpen} = this.state;
   
        return (
            <Loader show={showLoader} message={<Svg use="loader"/>} disableDefaultStyles={true}>
                <PageHeader title="Activity Reviews" actionItems={[
                    {label: <Svg use="add" />, tip: 'New Information System Activity Review', link: '/information-system-activity-reviews/new'},
                    //{label: <Svg use="upload" />, tip: 'Upload Information System Activity Review', options: [{label: 'Select CSV File', action: startImport}, {label: 'Download Import Template', link: 'https://s3.amazonaws.com/flightplan-public/import-templates/informationSystemActivityReviews-impot-template.csv', target: "_self"}], isOpen: isImportDropdownOpen, action: toggleImportDropdown},
                    {label: <Svg use='download'/>, tip:'Download',
                    options: [
                        {label: 'Download All Activity Reviews (CSV)', action: downloadAllInformationSystemActivityReviews}
                    ],
                    isOpen: isDownloadDropdownOpen, action: toggleDownloadDropdown},
                ]} />
                <section>
                    <List
                        className="meta"
                        inlineLabels={true}
                        items ={[
                            {label: 'Pending Activity Reviews', value: informationSystemActivityReviewsPending.length},
                            {label: 'Hide Reviewed', value: hideInactive, editing: true, inputType: 'toggle', onChange: toggleInactive}
                        ]}
                    />
                </section>                
                <section>
                    <div className="card">                      
                        <ReactTable
                            data={hideInactive ? informationSystemActivityReviewsPending : informationSystemActivityReviews}
                            defaultSorted={[initSortOrder]}
                            columns={[            
                                {Header: 'id', accessor: 'id', show: false},
                                {
                                    Header: 'Name',
                                    accessor: 'name',
                                },
                                {
                                    Header: 'Entered Date',
                                    accessor: 'dateEntered',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'
                                },
                                {
                                    Header: 'Date Performed',
                                    accessor: 'datePerformed',
                                    Cell: row => row.value ? <span>{moment(row.value).format('MM/DD/Y')}</span> : 'Not Set'
                                },                                                                
                                {
                                    Header: 'Requested By',
                                    accessor: 'requestedBy',
                                },
                                {
                                    Header: 'Performed By',
                                    accessor: 'performedBy',
                                },
                                {
                                    Header: 'Reported To',
                                    accessor: 'reportedTo',
                                },                                
                                {
                                    show: hideInactive ? false : true,
                                    Header: 'Status',
                                    accessor: 'rptReviewed',
                                    Cell: row => row.value ? 'Reviewed' : 'Pending',
                                    Filter: ({onChange}) => <DorpDownFilter options={[{label: 'Pending', value: false}, {label: 'Reviewed', value: true}]} action={onChange.bind(this)}/>            
                                }                                                                                                                                    
                            ]}
                            noDataText="No Logs Found"
                            onSortedChange={sort => sessionStorage.setItem(`informationSystemActivityReviews:sortOrder`, JSON.stringify(sort[0]))}
                            filterable={true}
                            defaultFilterMethod={defaultTableFilter}
                            getTdProps={(state, rowInfo, column, instance) => ({
                                onClick: e => rowInfo && this.props.history.push(`/information-system-activity-reviews/${rowInfo.row.id}`),
                                style: { cursor: 'pointer' }
                            })}
                        />
                    </div>
                </section>
                
           </Loader>
        );
    };
}

export default withRouter(InformationSystemActivityReviews);